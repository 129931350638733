.content-main {
  position: relative;
}

.checkbox {
  label {
    margin: 0 0 0 0.5rem;
  }
}

.w-35 {
  width: 35% !important;
}
.w-15 {
  width: 15% !important;
}
ul {
  padding: 0;
  margin: 0;
}
