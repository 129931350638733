.btn,
button {
  line-height: 1rem;

  &:not(.btn-sm) {
    height: 2.5rem;
  }
  &.btn-sm .fa {
    font-size: 1rem;
  }
}

button.btn-lg,
.btn-group-lg > button.btn {
  padding: 0.375rem 1.75rem;
}

.w-100 > button {
  width: 100%;
}
.btn.submit {
  position: relative;
  height: 40px;
  min-width: 100px;
}

.btn-default {
  background-color: #27b6f9;
}

.hidden-filters-btn {
  position: relative;
  .selected-filters {
    position: absolute;
    right: -4px;
    top: 0;
    background-color: #e26a76;
    color: #fff;
    font-size: 9px;
    border-radius: 100%;
    padding: 3px;
    width: 15px;
    height: 15px;
    line-height: 9px;
  }
}
