label {
  color: #02679e;
  font-size: small;
}

input:not([type='checkbox']):not([type='radio']) {
  width: 100%;
}

input[type='text']:disabled,
input[type='text'][readonly='readonly'],
input[type='password']:disabled,
input[type='password'][readonly='readonly'],
input[type='email']:disabled,
input[type='email'][readonly='readonly'],
input[type='url']:disabled,
input[type='url'][readonly='readonly'],
input[type='time']:disabled,
input[type='time'][readonly='readonly'],
input[type='date']:disabled,
input[type='date'][readonly='readonly'],
input[type='datetime-local']:disabled,
input[type='datetime-local'][readonly='readonly'],
input[type='tel']:disabled,
input[type='tel'][readonly='readonly'],
input[type='number']:disabled,
input[type='number'][readonly='readonly'],
input[type='search-md']:disabled,
input[type='search-md'][readonly='readonly'],
input[type='search']:disabled,
input[type='search'][readonly='readonly'],
textarea.md-textarea:disabled,
textarea.md-textarea[readonly='readonly'] {
  color: $black;
  background-color: tint-color(#e7ebef, 2%);
}

.qanda textarea {
  min-height: 5rem;
}

lib-shared-dynamic-form {
  &.header-labels label {
    @extend .h3, .text-black;
  }
  &.hide-save-button button.invisible {
    height: 0;
    padding: 0;
    border: 0;
  }

  .input-red-bg {
    input {
      background-color: rgba(255, 0, 0, 0.05);
    }
  }
}
