table {
  > :not(:first-child) {
    border-top: 1px solid #dee2e6;
  }
  &:not(.table__bottom-footer) {
    tbody {
      display: table-footer-group;
    }
    tfoot {
      display: table-row-group;
    }
  }
  &.p-datatable-table.table__bordered {
    tr > th,
    tr > td {
      border: 1px solid shade-color($primary, 30%);
    }
    > tfoot > tr > td,
    > thead > tr > th {
      background: shade-color($primary, 15%);
      color: color-contrast($primary);
    }
    > tfoot > tr > td {
      padding-left: 0.4rem;
      padding-right: 0.4rem;
    }
  }
}

tr.row-disabled {
  background-color: $disabled !important;
}

.wfdashboard,
.wfdashboard th,
.wfdashboard td {
  table-layout: auto !important;
  border: 1px solid black;
}

app-portfolio-operations th {
  white-space: nowrap;
}

.trans-status-failed,
.trans-status-returned {
  background-color: #d1372c !important;
  color: $white;

  td {
    &.ui-editable-column {
      background-color: #d1372c !important;
    }
    a {
      color: $white;
      text-decoration: underline;
    }
  }
}

.trans-status-processed,
.trans-status-allocated {
  background-color: $success !important;
  color: $white;

  td {
    &.ui-editable-column {
      background-color: $success !important;
    }
    a {
      color: $white;
      text-decoration: underline;
    }
  }
}

.trans-status-hold {
  background-color: $warning !important;
  color: $white;

  td {
    &.ui-editable-column {
      background-color: $warning !important;
    }
    a {
      color: $white;
      text-decoration: underline;
    }
  }
}

.table-clickable-rows {
  tbody > tr {
    cursor: pointer;

    &:hover {
      background: #f8f9fa;
    }
  }
}

.bg-light-green {
  background: #91d051;
}
.bg-light-red {
  background: #eeabaa;
}
.bg-amber {
  background: #f4b183;
}

.status-active,
.status-inactive,
.status-active-header {
  max-width: 65px;
}

.status-active::before {
  content: '';
  display: inline-block;
  width: 15px;
  height: 15px;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 7.5px;
  background-color: green;
}

.status-inactive::before {
  content: '';
  display: inline-block;
  width: 15px;
  height: 15px;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 7.5px;
  background-color: red;
}

.column-header-with-totals {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  /* allow overlap with sort trigger for better fit of titles */
  margin-right: -1.4rem;
  padding-right: 0.7rem;
  position: relative;

  > *:last-child {
    white-space: break-word;
  }
}
