@import '@aws-amplify/ui/styles.css';
html {
  font-family: var(--amplify-fonts-default-static);
}
@supports (font-variation-settings: normal) {
  html {
    font-family: var(--amplify-fonts-default-variable);
  }
}
.federated-sign-in-container {
  flex-direction: column;
  padding: 0 0 1rem 0;
}
.federated-sign-in-button {
  display: block;
}
.federated-sign-in-button-row {
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
