.dropdown-header {
  font-weight: bold;
  padding: 0.5rem 10px;
}

.profile-dropdown a {
  padding: 0.5rem;
  white-space: nowrap;
}

.ng-option-label {
  display: inline-block;
  min-height: 1em;
}

.dropdown {
  button:focus {
    outline: none;
  }
}
