.dialog-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.175);

  .dialog-title {
    margin: 0;
  }
}

.dialog-body {
  padding: 1rem;
}

.dialog-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 1.75rem;
  border-top: 1px solid rgba(0, 0, 0, 0.175);
}
