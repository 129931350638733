.attributes {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 0.5 * $grid-gutter-width 0;
  gap: 0.25rem;
  color: $text-muted;

  .attribute-item {
    margin: 0 0 0.5rem;
    border: 1px solid $gray-200;
  }

  .attribute-item-title {
    color: $black;
    background-color: tint-color($secondary, 40%);
    font-size: $font-size-sm;
    margin: 0;
    padding: 0.25rem;
    white-space: nowrap;
  }

  .attribute-item-value {
    margin: 0;
    padding: 0.25rem;
    background-color: tint-color($gray-100, 30%);
    &::after {
      content: '\00a0';
    }
  }
}
