.page-main-header {
  .nav-item .nav-link {
    padding: 0;
    text-align: center;
  }
}

app-header .p-menubar {
  border-radius: 0;
  border-width: 0 0 1px 0;
  background: #b9dcff;
  background: linear-gradient(0deg, #b9dcff 0%, #baebf5 100%);

  .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-content > .p-menuitem-link,
  .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-content > .p-menuitem-link:not(.p-disabled):hover,
  .p-menubar-root-list > .p-menuitem > .p-menuitem-content > .p-menuitem-link:not(.p-disabled):hover {
    background: #63bfe4;
    .p-submenu-icon,
    .p-menuitem-text {
      color: $white;
    }
  }
  .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content {
    background: #a4d1e3;
  }
  .p-submenu-list {
    z-index: 10;
  }
}
