// the minimal imports required to use bootstrap variables in components
@import 'bootstrap/scss/functions';

// colors
$green: #4cb589;
$red: #e26a76;
$blue: #5c9dd3;
$cyan: #39afc1;

$enable-gradients: true;

$primary: $blue;
$secondary: tint-color($primary, 70%);

$btn-padding-y: 0.5rem;
$btn-padding-x: 1rem;

// buttons
$btn-border-radius: 2px;
$btn-border-radius-lg: 2px;
$btn-border-radius-sm: 2px;

$link-decoration: none;
$link-hover-decoration: none;

$min-contrast-ratio: 2;

// typography
$font-size-base: 1rem;
$font-sizes: (
  1: $font-size-base * 2,
  2: $font-size-base * 1.75,
  3: $font-size-base * 1.5,
  4: $font-size-base * 1.25,
  5: $font-size-base,
  6: $font-size-base * 0.75,
);

@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/variables-dark';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/maps';
