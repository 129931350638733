@import 'variables';
@import 'font-awesome/scss/variables';
@import 'font-awesome/scss/mixins';

@import '@mca/styles/amplify';

@import 'bootstrap';
@import 'primeng';
@import '@mca/styles/components.scss';
@import '@mca/styles/utilities.scss';

.content-main {
  margin: 1rem;
}

.w-50 {
  width: 50%;
}

.tran-processed {
  background-color: $success !important;
  color: $white !important;
}

.page-actions {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.mcalist-grid .grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 6px;
  row-gap: 6px;
  align-items: stretch;

  &::before {
    display: none;
  }
}

app-lender-offers {
  .p-datatable .p-datatable-thead > tr > th {
    position: -webkit-sticky;
    position: sticky;
    top: 50px;
  }
}

.portal-logo {
  background-image: url('/assets/images/portal-logo.svg');
  background-size: 64px;
  background-position: center;
  background-repeat: no-repeat;

  &__icon {
    width: 14px;
    height: 14px;
    background-size: 14px;
  }
}
