.side-notes textarea {
  font-size: 1em;
  min-height: 20em;
  width: 100%;
}

.side-notes .card-data {
  background: $headers-color;
  font-size: 0.8em;
}

.side-notes .card.public-note {
  box-shadow: 0px 0px 9px 0px rgba(30, 196, 135, 0.5);
}

.side-notes .card-data:hover button {
  display: block !important;
}

.side-notes .card-data ul {
  list-style: none;
  padding: 1em 15px;
}

.side-notes .card-data li {
  display: inline-block;
  padding-right: 2em;
  white-space: nowrap;
}

.side-notes .card-data button {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
}

.side-notes .card-text {
  font-size: 1em;
}

.side-notes .p-inplace button {
  float: right;
}

.side-notes .p-inplace-display:hover {
  border-color: transparent;
  background: none;
  color: inherit;
}

.side-notes .p-panel,
.side-notes .p-panel-content,
.side-notes .p-panel-header {
  padding: 2px;
}

.side-notes .p-panel-icons {
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
}
