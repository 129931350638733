.w-20 {
  width: 20%;
}

.pre-lined {
  white-space: pre-line;
}

.min-w-0 {
  min-width: 0 !important;
}
