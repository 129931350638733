.p-toast.p-component {
  width: 35rem;
}
.p-toast-message-text {
  width: 27rem;
  word-break: break-word;
}
.p-toast-detail {
  white-space: pre-line;
}
