p-panelmenu[styleclass='p-panelmenu--hovered'] {
  display: block;
  position: relative;
  width: 74px;
}
.p-panelmenu--hovered {
  a {
    color: $gray-700;
  }
  .p-panelmenu-header > a .p-menuitem-icon {
    margin-right: 0;
  }

  .p-menuitem-text,
  .p-toggleable-content {
    display: none;
  }

  .p-panelmenu-header-link {
    justify-content: center;
  }

  .p-panelmenu-panel a.p-menuitem-link-active {
    @include gradient-bg($gray-300);
  }

  &:hover {
    z-index: 1000;
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: 0 0 3px 1px #a6d5fa;

    .p-menuitem-text,
    .p-toggleable-content {
      display: block;
    }

    .p-panelmenu-header-link {
      justify-content: start;
    }

    .p-panelmenu-header > a .p-menuitem-icon {
      margin-right: 0.5rem;
    }
  }
}
