.ui-float-label {
  position: relative;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.ui-float-label input,
.ui-float-label .form-control,
.p-multiselect,
.p-dropdown {
  border-top-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  border-radius: 0;
}

.ui-float-label .form-control {
  padding: 0.5rem;
  height: auto;
  &:hover {
    border-color: $input-focus-border-color;
  }
}

.ui-float-label > label {
  top: -8px;
  left: 10px;
  margin-top: 0;
  margin-bottom: 0;
  padding: 2px 7px;
  border-radius: 4px;
  background: $headers-color;
  color: $gray-900;
  white-space: nowrap;
  z-index: 1;
}

.ui-float-label .was-validated input[type='text']:valid + label {
  color: #00c851 !important;
}

.ui-float-label .was-validated input[type='text']:invalid + label {
  color: #f44336 !important;
}

.ui-float-label .was-validated .form-control:valid:focus {
  box-shadow: 0 1px 0 0 #00c851 !important;
}

.ui-float-label .was-validated .form-control:valid {
  border-color: #00c851 !important;
}

.ui-float-label .was-validated .form-control:invalid:focus {
  box-shadow: 0 1px 0 0 #f44336 !important;
}

.ui-float-label .was-validated .form-control:invalid {
  border-color: #f44336 !important;
}

.ui-float-label .form-control:focus {
  box-shadow: none;
}

.ui-float-label .validate {
  margin-bottom: 2.5rem;
}

.ui-float-label .prefix {
  top: 0.25rem;
  font-size: 1.75rem;
}

.ui-float-label .prefix ~ input,
.ui-float-label .prefix ~ textarea {
  margin-left: 2.5rem;
  width: calc(100% - 2.5rem);
}

.ui-float-label .prefix ~ label {
  margin-left: 2.5rem;
}

.ui-float-label .prefix ~ .form-text {
  margin-left: 2.6rem;
}

.ui-float-label .prefix {
  position: absolute;
  transition: color 0.2s;
}

.ui-float-label .prefix.active {
  color: #4285f4;
}

.ui-float-label.form-lg .validate {
  margin-bottom: 2.8rem;
}

.ui-float-label.form-lg label {
  font-size: 1.25rem;
}

.ui-float-label.form-lg label.active {
  font-size: 0.95rem;
}

.ui-float-label.form-lg .prefix {
  top: 0.4rem;
  font-size: 2rem;
}

.ui-float-label.form-lg .prefix ~ input,
.ui-float-label.form-lg .prefix ~ textarea {
  margin-left: 3rem;
  width: calc(100% - 3rem);
}

.ui-float-label.form-lg .prefix ~ label {
  margin-left: 3rem;
}

.ui-float-label.form-lg .prefix ~ .form-text {
  margin-left: 3.1rem;
}

.ui-float-label.form-sm .validate {
  margin-bottom: 2.3rem;
}

.ui-float-label.form-sm label {
  font-size: 0.875rem;
}

.ui-float-label.form-sm label.active {
  font-size: 0.75rem;
}

.ui-float-label.form-sm .prefix {
  top: 0.35rem;
  font-size: 1.5rem;
}

.ui-float-label.form-sm .prefix ~ input,
.ui-float-label.form-sm .prefix ~ textarea {
  margin-left: 2rem;
  width: calc(100% - 2rem);
}

.ui-float-label.form-sm .prefix ~ label {
  margin-left: 2rem;
}

.ui-float-label.form-sm .prefix ~ .form-text {
  margin-left: 2rem;
}

.ui-float-label textarea.md-textarea {
  overflow-y: hidden;
  padding: 1.5rem 0;
  resize: none;
}

.ui-float-label textarea.md-textarea-auto {
  padding: 0;
  padding-top: 1.5rem;
}

.ui-float-label.input-group label {
  top: 0;
  margin-bottom: 0;
}

.ui-float-label.input-group .input-group-text {
  background-color: #e0e0e0;
}

.ui-float-label.input-group .input-group-text.md-addon {
  border: none;
  background-color: transparent;
  font-weight: 500;
}

.ui-float-label.input-group .form-control {
  margin: 0;
}

.ui-float-label .prefix.active {
  color: #0f84bf;
}

.ui-float-label-text {
  padding: 0.43em 0;
  margin: 0.5em 0;
}

.ui-float-label.form-sm .prefix {
  top: 0;
}
