.panel--hovered {
  p-panel {
    display: block;
    position: relative;
    width: 100%;
    .p-panel-content {
      display: none;
    }
  }
  .notes-list {
    display: none;
  }

  &:hover {
    position: absolute;
    z-index: 1000;
    right: 20px;
    box-shadow: 0 0 3px 1px #a6d5fa;
    background-color: #f8f9fa;
    width: 250px;
    .p-panel {
      .p-panel-content {
        display: block;
      }
    }
    .notes-list {
      display: block;
    }
  }
}
