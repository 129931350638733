.button-menu-item {
  margin-left: 0;
  margin-right: 0;
  padding: 0.25rem 1rem;
  button {
    width: 100%;
    border: none;
    background: none;
    text-align: left;
    font-size: 14px;
  }

  &:hover,
  &:focus {
    background: rgba(0, 0, 0, 0.1);
  }
}

.button-menu-toggle::after {
  margin: 0 0 0 0.5rem;
  position: relative;
  top: 0.05rem;
  border: 0;
  vertical-align: baseline;
  @include fa-icon();
  content: $fa-var-ellipsis-h;
}
.button-menu-toggle.dotted-menu::after {
  margin: 0;
}

.button-menu-overlay .p-overlaypanel-content {
  padding: 0.5rem 0;
  min-width: 10rem;
}
