/**
* Overrides for primeng lib
*/

.p-component .p-highlight:not(.p-button, .p-stepper-header),
.ui-state-highlight {
  background: #c1dfea;
}

p-button.p-stepper-action {
  display: flex;
}

@media screen and (max-width: 64em) {
  app-lender-offers {
    .p-datatable .p-datatable-thead > tr > th {
      top: 100px;
    }
  }
}

p-panel[toggler='header'] .p-panel-header {
  cursor: pointer;
}

.p-panel .p-panel-header .p-panel-header-icon:focus {
  box-shadow: none;
}

input.p-inputtext,
.p-component input.ui-state-default,
p-chips .p-chips .p-inputtext {
  background-color: #fff;
  border: none;
  border-bottom: 1px solid #bdbdbd;
  border-radius: unset;
}

.p-inputtext:enabled:focus {
  box-shadow: 0 1px 2px 0 #a6d5fa;
}

.p-dataview-loading {
  opacity: 1;
  background: rgba(255, 255, 255, 0.5);
}
.pi-spinner::after {
  display: none;
}

.p-editable-column:not(.p-cell-editing) p-celleditor {
  display: inline-block;
  position: relative;
  width: 100%;

  &::after {
    @include fa-icon();
    content: $fa-var-pencil;
    position: absolute;
    top: -8px;
    right: -5px;
    font-size: 8px;
    color: rgba(0, 0, 0, 0.1);
  }
}

.p-tabview .p-tabview-panels {
  padding: 1rem 0.2rem;
}
.p-tabview .p-tabview-nav li {
  background: none;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  margin-top: 4px;
  border-radius: 3px;
}

p-dropdown,
p-multiselect,
p-chips .p-chips {
  display: inline-block;
  width: 100%;

  > .p-component {
    width: 100%;
    min-height: 40px;
  }
}
.p-dropdown-label {
  min-height: 2.5rem;
}
.p-dropdown-label-empty::after {
  content: '\00a0';
}

p-multiselect.wide-dropdown .p-multiselect-panel.p-component {
  min-width: 200px;
}

.p-paginator {
  display: flex;
  p-dropdown {
    width: auto;
    margin-left: auto;
  }
}
.p-paginator button.p-disabled {
  display: none;
}

.p-datatable-scrollable .p-datatable-wrapper {
  overflow: auto;
}
.p-datatable table {
  border-collapse: collapse;
  min-width: 100%;
  table-layout: fixed;
}
.p-datatable.layout-auto table {
  table-layout: auto;
}
.p-datatable.p-component .p-datatable-tbody > tr > td {
  vertical-align: middle;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  border-color: $border-color;
  border-width: 0 0 1px 0;
}
.p-datatable .p-datatable-tbody > tr {
  color: $black;
}
.p-datatable-tfoot > tr > td {
  background: lightblue;
  color: $black;
}
.p-datatable-thead > tr > th {
  background: $headers-color;
  color: $black;
}
.p-datatable .p-datatable-tfoot > tr > td,
.p-datatable.p-datatable-sm .p-datatable-tfoot > tr > td {
  padding: 0;
  border: none;
}

.p-datatable .p-datatable-tfoot > tr.padding-adjusted > td {
  padding: 0.25rem 0.4rem !important;
}

.p-datatable .p-datatable-thead > tr > th,
.p-datatable .p-datatable-tbody > tr > td,
.p-datatable .p-datatable-tfoot > tr > td .grid-footer-cell {
  padding: 0.25rem 0.4rem !important;
}

.p-datatable-scrollable .p-datatable-thead > tr,
.p-datatable-scrollable .p-datatable-tbody > tr,
.p-datatable-scrollable .p-datatable-tfoot > tr {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
}
.p-datatable-scrollable .p-datatable-thead > tr > th,
.p-datatable-scrollable .p-datatable-tbody > tr > td,
.p-datatable-scrollable .p-datatable-tfoot > tr > td {
  display: flex;
  flex: 1 1 0;
  align-items: center;
}
.p-datatable-scrollable .p-datatable-tbody {
  border-bottom: 3px lightblue solid;
}

.p-datatable-scrollable .p-datatable-table {
  width: max-content;
}
.p-datatable .p-scroller-viewport {
  .p-datatable-table {
    display: flex;
    flex-flow: column;
  }
  .p-datatable-thead > tr > th {
    height: 2.5rem;
  }
  .p-datatable-tfoot {
    position: sticky;
    order: 1;
    top: 2.5rem;
    & > tr > td {
      height: 2rem;
    }
  }
  tbody {
    order: 2;
    top: 4.5rem;
    margin-bottom: -4.5rem;
  }
}
.p-datatable.thick-thead .p-scroller-viewport .p-datatable-thead > tr > th {
  height: 5rem;
}

.p-datatable .p-datatable-header {
  background: tint-color($headers-color, 50%);
}

.p-datatable .text-white a {
  color: $white;
}

.p-component-overlay {
  background: rgba(255, 255, 255, 0.4);
}

.p-no-submenu-icon {
  .p-submenu-icon {
    display: none;
  }
}

.p-menubar-root-list > .p-menuitem-active > p-menubarsub > ul.p-submenu-list {
  display: grid;
  grid-auto-flow: row;
  grid-template: repeat(1, 1fr) / repeat(1, 1fr);
  width: max-content;

  .p-menuitem {
    background: $white;
  }
}
.p-menubar-root-list > .p-menuitem-active.submenu-wide > p-menubarsub > ul.p-submenu-list {
  grid-template: repeat(1, 1fr) / repeat(2, 1fr);
}
.menu-section-header {
  grid-column: span 2;
}

.p-menubar .p-menubar-root-list > .p-menuitem.p-no-submenu-icon > .p-menuitem-content > .p-menuitem-link .p-menuitem-icon {
  margin: 0;
}
.p-menubar > p-menubarsub {
  width: 100%;
  .p-menuitem {
    font-size: 14px;
  }
}
.p-menubar-root-list {
  width: 100%;
}
.p-menu-badged-lable {
  &.pay-attention {
    .p-menuitem-icon {
      font-weight: bold;
    }
    .p-menuitem-icon,
    .p-menuitem-text {
      color: $red !important;
    }
  }
  .p-menuitem-text {
    position: absolute;
    top: 1px;
    right: 1px;
    width: 1.2em;
    text-align: left;
  }
}

.submenu-right {
  .p-submenu-list {
    right: 0;
  }

  + p-menubarsub {
    .p-submenu-list {
      right: 0;
    }
  }
}

.p-tabmenuitem {
  white-space: nowrap;
}
.p-tabmenu .p-tabmenu-nav {
  padding: 0;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem,
.p-tabview .p-tabview-nav li,
.p-tabview .p-tabview-nav li.p-highlight {
  background: none;
  display: list-item;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link,
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  @include gradient-bg($primary);
  color: color-contrast($primary);
  justify-content: center;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link,
.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
  color: color-contrast(shade-color($primary, 7.5%));
  @include gradient-bg(shade-color($primary, 7.5%));
  border-color: tint-color($primary, 7.5%);
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link,
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link.p-menuitem-link-active,
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  color: color-contrast(tint-color($primary, 70%));
  @include gradient-bg(tint-color($primary, 70%));
}

.p-tabmenu.tabmenu-light {
  .p-tabmenu-nav-content {
    overflow: visible;
  }
  .p-tabmenu-nav {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  .p-tabmenu-nav .p-tabmenuitem {
    background: $white;
    color: color-contrast($white);
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: -1px;
    overflow: hidden;
    box-sizing: content-box;
  }
  .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    background: none;
    color: inherit;
    font-weight: normal;
  }
  .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover,
  .p-tabmenu-nav .p-tabmenuitem.p-highlight {
    color: $primary;
    background: $white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: none;
    margin: -1px;
    padding-bottom: 1px;
    z-index: 1;
  }
  .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus,
  .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link,
  .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link,
  .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link.p-menuitem-link-active {
    box-shadow: none;
    border: none;
    background: none;
    color: inherit;
  }
}

.p-fieldset {
  border: 1px solid $gray-600;
  margin-bottom: 1rem;

  .p-fieldset-legend {
    border: 1px solid $gray-600;
    box-shadow: 0 0 1px 0 #000;
    box-sizing: content-box;
    margin: -1px 0 -1px -1px;
    @include gradient-bg($gray-200);

    a {
      color: $gray-700;
    }
  }

  &.p-fieldset-toggleable .p-fieldset-legend:hover {
    border-color: $gray-500;
    @include gradient-bg($gray-100);
  }
}

.p-checkbox-label {
  margin-bottom: 0;
}
.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #2196f3;
  background: #2196f3;
}

.wide-calendar-input .p-calendar {
  width: 100%;
}

.p-datatable.p-datatable-sm .p-datatable-thead > tr > th,
.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  font-size: 14px;
  padding: 0.25rem 0.25rem !important;
}

.p-accordion {
  .p-accordion-header .p-accordion-header-link {
    color: $gray-700;
    font-size: 12px;
    padding: 0.5rem;
    .pi {
      font-size: 0.5rem;
    }
  }
  .p-accordion-content {
    padding: 0;
    .p-datatable {
      margin-bottom: 0 !important;
    }
  }
}

.p-speeddial.p-speeddial-opened {
  z-index: 100;
}

p-autocomplete .p-autocomplete {
  width: 100%;

  .p-autocomplete-loader {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 2;
  }
}

.p-selectbutton .p-button.p-highlight {
  background: $primary;
  border-color: $primary;
}

.p-megamenu-root-list > .p-menuitem-active > .p-megamenu-panel {
  z-index: 10;
}

.p-datatable .p-datatable-tbody > tr.disable-outline:focus {
  outline: none;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link {
  padding: 0.75rem 0.75rem;
  border-radius: 3px;
}

.p-steps.p-component {
  .p-highlight {
    background: none;
  }
  .p-steps-item .p-menuitem-link:not(.p-disabled):focus {
    box-shadow: none;
  }
  .p-steps-number,
  .p-steps-title {
    user-select: none;
  }
  .p-menuitem-link {
    cursor: pointer;
  }
}
.p-steps__hidden-titles .p-steps-title {
  visibility: hidden;
}

.p-picklist-item.cdk-drag-preview {
  z-index: 1060 !important;
}
.p-picklist .p-picklist-buttons .p-button {
  margin-bottom: 0.5rem;
  border-radius: 3px;
}
.p-dropdown-panel .p-dropdown-items li.p-dropdown-item,
p-dropdown.p-inputwrapper {
  min-height: 40px;
  max-height: 40px;
}

.p-tooltip.wide-tooltip {
  min-width: 500px;
}

.p-hidden {
  display: none !important;
}
//sidebar

.p-sidebar-left {
  width: 40rem;
}
