.card {
  margin-bottom: 30px;
  border: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  border-radius: 8px;
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.1);

  .form-group {
    margin-bottom: 20px;

    .col-form-label {
      padding-bottom: calc(0.375rem + 1px);
      margin-bottom: 0;
      font-size: inherit;
      line-height: 1.5;
      color: #999;
    }
  }

  .btn-group-lg > .btn,
  .btn-lg {
    font-size: 18px;
    padding: 0.375rem 1.75rem;
    letter-spacing: 0.2px;
  }
}
