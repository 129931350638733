.p-dialog {
  --mca-dialog-border-radius: calc(0.5rem - 1px);
  border: 1px solid rgba(0, 0, 0, 0.175);
  border-radius: var(--mca-dialog-border-radius);
  width: 500px;
  background: white;
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);

  &.dialog__size-lg {
    width: 800px;
  }
  &.dialog__size-xl {
    width: 1140px;
  }

  .p-dialog-header {
    padding: 0.2rem 0.5rem;
    background: lightblue;
  }
  .p-dialog-content {
    padding: 0;
    border-radius: var(--mca-dialog-border-radius);
  }
  .p-dialog-footer button {
    margin-left: 0.5rem;
  }
}
